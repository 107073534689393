import React, { Component } from "react";
import aboutPhoto from "../Assets/AboutPhoto.jpg";

class About extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="container-fluid" style={{zIndex: -3}}>
          <div className="row">
            <div className="col about-col" style={{ height: "100vh" }}>
            </div>
          </div>
        </div>
        <div className="introdiv-3" style={{paddingLeft: "1vw"}}>
            <h1>
                About Me
            </h1>
            <div className="about-div">
                I'm an up and coming software developer with more than enough passion to go around.
                My love for programming goes back to high school and the TI-84 calculator. Looking for someone to do a lot with very little?
                I'm your guy.
            </div>
            <div className="about-div">
                I also do work with music production, you can find my work <a href="https://soundcloud.com/hydrophoil">here!</a>
            </div>
        </div>
        <div className="secret-div">
        </div>
      </React.Fragment>
    );
  }
}

export default About;
