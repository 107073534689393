import React, { Component } from "react";

class Resume extends Component {
  render() {
    return (
      <React.Fragment>
        <div>
            <iframe
              className="responsive-iframe"
              src="https://docs.google.com/document/d/e/2PACX-1vTE0UcJyvUZK03XxwQ8mRGajilZCLur4E1zrAeU4G19DRtujDrZQURETeMHpb7iSsN1DFh-u67hq-Kp/pub?embedded=true"
            ></iframe>
        </div>
        <div
          className="introdiv"
          style={{ background: "rgb(159, 135, 190)" }}
        ></div>
        <div className="introdiv-2"></div>
      </React.Fragment>
    );
  }
}

export default Resume;
