import React, { Component } from "react";
import { Link } from "react-router-dom";
import { YouTube } from "../Assets/YouTubeLogo.png";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: false,
      goToggle: false,
      code: [""],
      offset: 0,
      rgb1Start: [98, 102, 102],
      rgb2Start: [0, 0, 0],
      op1: 0,
      op2: 0,
      op3: 0,
      winWidth: 0,
      winHeight: 0,
      pullCode: `import React, { Component } from "react"; class Resume extends Component { render() {return (<React.Fragment><div><div
 className="container lock-it"
 width="100%"
 height="100vh"
 style={{ alignContent: "center" }}
>
<iframe
 className="responsive-iframe"
 src="https://docs.google.com/document/d/e/2PACX-1vTE0UcJyvUZK03XxwQ8mRGajilZCLur4E1zrAeU4G19DRtujDrZQURETeMHpb7iSsN1DFh-u67hq-Kp/pub?embedded=true"
></iframe>
</div>
</div>
<div
 className="introdiv"
 style={{ background: "rgb(159, 135, 190)" }}
></div>
<div className="introdiv-2"></div>
</React.Fragment>
);
}}
      
      export default Resume;`,
      count: 0,
      intervalId: null,
      toggleIntervalId: null,
    };
  }

  componentDidMount() {
    let intervalId = setInterval(
      () => this.newCharacter(),
      Math.random() * 50 + 16
    );
    this.setState({ intervalId: intervalId });
    window.addEventListener("resize", () => this.updateWindowDimensions());
    this.updateWindowDimensions();
  }
  componentWillUnmount() {
    clearInterval(this.intervalId);
    window.removeEventListener("resize", () => this.updateWindowDimensions());
  }

  updateWindowDimensions() {
    this.setState({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  }

  newCharacter() {
    let newOp1 = this.state.op1;
    let newOp2 = this.state.op2;
    let newOp3 = this.state.op3;
    if (newOp1 < 1) {
      newOp1 += 0.05;
    }
    if (newOp2 < 1 && newOp1 >= 1) {
      newOp2 += 0.05;
    }
    if (newOp3 < 1 && newOp2 >= 1) {
      newOp3 += 0.05;
    }
    this.setState({
      op1: newOp1,
      op2: newOp2,
      op3: newOp3,
    });
    let str = this.state.pullCode.charAt(this.state.count);
    let newCode = this.state.code;
    let codeString = newCode[this.state.code.length - 1] + str;
    newCode[this.state.code.length - 1] = codeString;
    if (str === ">" || str === "}" || str === ";") {
      newCode = newCode.concat([""]);
    }
    if (newCode.length > 25) {
      let count = 0;
      newCode = newCode.filter((line) => {
        count += 1;
        return count !== 1;
      });
    }

    let newCount = this.state.count + 1;
    if (newCount === this.state.pullCode.length) {
      newCount = 0;
    }
    this.setState({ code: newCode, count: newCount });
  }
  handleGoToggle() {
    let newOffset = this.state.offset;
    let newRgb1 = this.goToColor(this.state.rgb1Start, [182, 213, 218]);
    let newRgb2 = this.goToColor(this.state.rgb2Start, [47, 112, 122]);
    this.setState({
      rgb1Start: newRgb1,
      rgb2Start: newRgb2,
    });
    if (newOffset > 2000) {
      this.setState({ toggle: true });
      clearInterval(this.state.toggleIntervalId);
    }
    this.setState({ offset: newOffset + 40 });
  }

  handleToggle() {
    this.setState({
      goToggle: true,
      toggleIntervalId: setInterval(() => this.handleGoToggle(), 16.66),
    });
  }

  renderFirst() {
    return (
      <div>
        <div>
          <h1 style={{ opacity: this.state.op1 }}>On Time </h1>
          <h1 style={{ opacity: this.state.op2 }}>On Model</h1>
          <h1 style={{ opacity: this.state.op3 }}>On Brand</h1>
          <p style={{ opacity: this.state.op3 }}>
            Software engineering done right the first time.
          </p>
        </div>
        <button
          className="btn btn-primary"
          style={{ opacity: this.state.op3 }}
          onClick={() => this.handleToggle()}
        >
          See More
        </button>
      </div>
    );
  }
  renderPortfolio() {
    let portfolio = [
      {
        name: "HayPenny",
        url: "http://hay-penny.herokuapp.com/",
        github: "https://github.com/thesebonesalone/haypenny-frontend",
        src: "https://www.youtube.com/embed/_IfHd5MDI8w",
        description:
          "A React Based social media app using a Rails Backend, Heroku Scheduler and JWT authentication.",
      },
      {
        name: "DCCCVIII",
        url: "http://dcccviii.herokuapp.com/",
        github: "https://github.com/TJBigelow/DCCCVIII",
        src: "https://www.youtube.com/embed/EU0GY0IEkTU",
        description:
          "A JS sequencer that uses a Rails Backend to store and recall different songs.",
      },
      {
        name: "Bonkers React",
        url: null,
        github: "https://github.com/thesebonesalone/BonkersReact",
        src: "https://www.youtube.com/embed/l3EEBhR7jqk",
        description:
          "A React Based game engine that can be scaled to make 2D video games directly in the browser.",
      },
    ];
    return (
      <div className="container-fluid see-more">
        <div className="row">
          {portfolio.map((item) => {
            return (
              <div
                className="card col-lg"
                style={{ color: "black", textAlign: "center" }}
              >
                <iframe
                  width="100%"
                  height="315"
                  src={item.src}
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen={true}
                ></iframe>
                <h3 className="card-title">{item.name}</h3>

                <div>
                  {this.state.winWidth <= 800 ? (
                    <a
                      target="_blank"
                      href={item.src}
                      className="btn btn-danger youtube"
                    >
                      YouTube
                    </a>
                  ) : null}
                  {item.url !== null ? (
                    <a
                      target="_blank"
                      href={item.url}
                      className="btn btn-primary chrome"
                    >
                      Web App
                    </a>
                  ) : null}
                  {item.github !== null ? (
                    <a
                      target="_blank"
                      href={item.github}
                      className="btn btn-secondary github"
                    >
                      Github
                    </a>
                  ) : null}
                </div>
                <div className="card-text">{item.description}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  parseLetter(code) {
    let color = "lightgreen";
    let lastColor = color;
    let splitCode = code.split("");
    return splitCode.map((phrase) => {
      let changed = false;
      if (phrase === `"` && color !== "orange") {
        lastColor = color;
        color = "orange";
        changed = true;
      }
      if (phrase === "<" && color !== "orange") {
        lastColor = color;
        color = "lightblue";
      }
      if (
        (phrase === "=" ||
          phrase === "+" ||
          phrase === "-" ||
          phrase === "/" ||
          phrase === "*" ||
          phrase === "(" ||
          phrase === ")" ||
          phrase === "{" ||
          phrase === "}" ||
          phrase === "." ||
          phrase === ";") &&
        color !== "orange"
      ) {
        lastColor = color;
        color = "white";
      }

      let returnColor = color;
      if (
        (phrase === "=" ||
          phrase === "+" ||
          phrase === "-" ||
          phrase === "/" ||
          phrase === "*" ||
          phrase === "(" ||
          phrase === ")" ||
          phrase === "{" ||
          phrase === "}" ||
          phrase === "." ||
          phrase === ";") &&
        color === "white"
      ) {
        color = lastColor;
      }
      if (phrase === '"' && color === "orange" && changed === false) {
        color = lastColor;
      }
      if (phrase === ">" && color !== "orange") {
        color = lastColor;
      }
      return <a style={{ color: returnColor }}>{phrase}</a>;
    });
  }

  renderCode() {
    let count = 0;
    let color = "green";
    return this.state.code.map((code) => {
      count += 1;
      return (
        <p className="code-p" style={{ color: color }}>
          <a style={{ color: "white" }}>
            {count === this.state.code.length ? "▋" : ":"}
          </a>
          {this.parseLetter(code)}
        </p>
      );
    });
  }

  goToColor(input, wantedColor) {
    let count = 0;
    let newColor = input.map((value) => {
      let newValue = value;
      if (value < wantedColor[count]) {
        newValue = value + 3;
      } else if (value > wantedColor[count]) {
        newValue = value - 3;
      }
      count += 1;
      return newValue;
    });
    return newColor;
  }

  // rgb(108, 200, 214)
  // 182, 213, 218

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            position: "relative",
            top: 0 - this.state.offset,
            color: "white",
          }}
        >
          {this.renderFirst()}
        </div>
        <div
          style={{
            position: "relative",
            top: 1700 - this.state.offset,
            color: "white",
          }}
        >
          {this.renderPortfolio()}
        </div>
        <div
          className="introdiv"
          style={{
            background: `rgb(${this.state.rgb1Start[0]},${this.state.rgb1Start[1]},${this.state.rgb1Start[2]})`,
          }}
        >
          <div
            style={{
              position: "relative",
              top: 0 - this.state.offset,
              color: "white",
            }}
          >
            {this.state.toggle ? null : this.renderCode()}
          </div>
        </div>
        <div
          className="introdiv-2"
          style={{
            background: `rgb(${this.state.rgb2Start[0]},${this.state.rgb2Start[1]},${this.state.rgb2Start[2]})`,
          }}
        ></div>
      </React.Fragment>
    );
  }
}

export default Home;
