import React, { Component } from "react";
import emailjs, { init } from "emailjs-com";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feedback: "",
      name: "",
      email: "will.avery21@gmail.com",
      replyEmail: "",
      phoneNumber: "",
    };
  }

  handleChange(e) {
    switch (e.target.name) {
      case "name":
        this.setState({ name: e.target.value });
        break;
      case "test-mailing":
        this.setState({ feedback: e.target.value });
        break;
      case "reply-email":
        this.setState({ replyEmail: e.target.value });
        break;
      case "phone-number":
        this.setState({ phoneNumber: e.target.value });
        break;
    }
  }
  handleSubmit(e) {
    e.preventDefault();
    const templateId = "template_ok8wbxc";
    const message = `${this.state.name} says \n ${this.state.feedback} \n They can be reached via email at: ${this.state.replyEmail} \n Or by phone at: ${this.state.phoneNumber}`;
    this.sendFeedback(templateId, {
      message: message,
      from_email: "duikhead2@gmail.com",
      from_name: this.state.name,
      reply_to: this.state.email,
    });
  }

  sendFeedback(templateId, variables) {
    emailjs.init("user_RHa9Rj9bbP92aKSxC4cma");
    emailjs
      .send("service_kqhhqbq", templateId, variables)
      .then((res) => {
        console.log("email sent");
      })
      .catch((err) => console.error("failure", err));
  }

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid contact" width="100%"
            height="100vh"
            style={{ alignContent: "center" }}>
          <form className="test-mailing">
            <h2>Contact Me</h2>
            <div>
              <input
                type="text"
                name="name"
                placeholder="Your Name Here"
                onChange={(e) => this.handleChange(e)}
                value={this.state.name}
              />
              <input
                type="text"
                name="reply-email"
                placeholder="Your Email"
                onChange={(e) => this.handleChange(e)}
                value={this.state.replyEmail}
              />
              <input
                type="text"
                name="phone-number"
                placeholder="Your Phone Number"
                onChange={(e) => this.handleChange(e)}
                value={this.state.phoneNumber}
              />
              <textarea
                id="test-mailing"
                name="test-mailing"
                onChange={(e) => this.handleChange(e)}
                placeholder="Leave me a message"
                required
                value={this.state.feedback}
              />
            </div>
            <input
              type="button"
              value="Send"
              className="btn btn-primary"
              onClick={(e) => this.handleSubmit(e)}
            />
          </form>
        </div>
        <div
          className="introdiv"
          style={{ background: "rgb(182, 213, 218)" }}
        ></div>
        <div className="introdiv-2"></div>
      </React.Fragment>
    );
  }
}

export default Contact;
